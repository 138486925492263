import { useEffect, useState } from "react";
import { InvestorPortalApi } from "../Api/api.InvestorPortal";
import ReportsApi from "../Api/api.Reports";
import { InvestorsList, Loading } from "../Modelos/Investors.model";
import { IUseInvestorProps } from "./IUseInvestorPortal";
import { useNavigate } from "react-router-dom";

const useInvestors = (props: IUseInvestorProps) => {
  const { language, firstEntrance, setFirstEntrance } = props;
  const [investorsData, setInvestorsData] = useState<InvestorsList[]>([]);
  const [allInvestorsData, setAllInvestorsData] = useState<InvestorsList[]>([]);
  const [loading, setLoading] = useState<Loading>({table: false, chart: false });
  const [quarter, setQuarter] = useState<string>("");
  const [quarterDate, setQuarterDate] = useState<string>("");
  const [homeFlowsChart, setHomeFlowsChart] = useState<any>([]);
  const [selectedInvestorsFlowChart, setSelectedInvestorsFlowChart] = useState<number[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    getFundFigures(language);
  }, [language]);
  
  useEffect(() => {
    if(selectedInvestorsFlowChart.length > 0){
      getHomeFlowsChart();
    }else{
      setHomeFlowsChart([]);
    }
  }, [selectedInvestorsFlowChart]);

  const getHomeFlowsChart = async () => {
    setLoading({ ...loading, chart: true });
    await InvestorPortalApi.getFlowChart(selectedInvestorsFlowChart)
    .then((response: any) => {
      if (response.success) {
        if(response.data.length > 0){
          let newResponse = response.data.filter((item: any) => item.column !== "totalValue");
          let residualValue = response.data.find((item: any) => item.column === "residualValue");
          let distributionValue = response.data.find((item: any) => item.column === "distribution");

          if(residualValue && distributionValue){
            let newObject = {
              column: "totalValue",
              value1: null,
              value2: null,
              rpi: distributionValue.value2,
              dpi: (residualValue.value2 - distributionValue.value2)
            }
            newResponse.push(newObject);
          }
          setHomeFlowsChart(newResponse);
        }
      }
      setLoading({ ...loading, chart: false })
    })
    .catch((error: any) => {
      console.log(error);
    })
    .finally(() => setLoading({ ...loading, chart: false }));
  };

  const handleInvestorsCheckboxFlowChart = (checked: boolean,idInvestor: number) => {
    if (checked) {
      setSelectedInvestorsFlowChart([
        ...selectedInvestorsFlowChart,
        idInvestor,
      ]);
    } else {
      setSelectedInvestorsFlowChart(
        selectedInvestorsFlowChart.filter((item) => item !== idInvestor)
      );
    }
  };

  const getFundFigures = async (lang: string) => {
    setLoading({ ...loading, table: true });
    await InvestorPortalApi.getQuarterInvestorData(lang)
      .then((response: any) => {
        if (response.success) {
          setQuarterDate(response.data.quarterDate);
          setQuarter(response.data.quarter);
          setInvestorsData(response.data.peips);
          setAllInvestorsData(response.data.peips);
          setLoading({ ...loading, table: false });
          setFirstEntrance(false);

          let hasInvestors = response.data?.peips?.some((peip: any) => peip.investorsList.length > 0);
          if(!hasInvestors && firstEntrance){
            navigate('/Details?detailsBy=investors')
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoading({ ...loading, table: false }));
  };

  const viewCapitalCall = async (idInvestor: number, date: string) => {
    let data = {
      investorIdList: [idInvestor],
      date: date,
      language: language.toUpperCase(),
    };
    await ReportsApi.GenerateCapitalCall(data)
      .then((res) => {
        var blob = new Blob([res], { type: "application/pdf" });
        var link = window.URL.createObjectURL(blob);
        window.open(link);
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoading({ ...loading, table: false }));
  };

  const filterInvestors = (value: string) => {
    if (value === "") {
      setInvestorsData(allInvestorsData);
    } else {
      let filtered = allInvestorsData.filter((item: any) =>
        item.investorsList.some((investor: any) =>
            investor.investorName.toLowerCase().includes(value.toLowerCase())
        )
      ).map((filteredItem: any) => ({
        ...filteredItem,
        investorsList: filteredItem.investorsList.filter((investor: any) =>
            investor.investorName.toLowerCase().includes(value.toLowerCase())
        )
      }));
      setInvestorsData(filtered);
    }
};

  return {
    investorsData,
    loading,
    viewCapitalCall,
    quarter,
    homeFlowsChart,
    selectedInvestorsFlowChart,
    handleInvestorsCheckboxFlowChart,
    quarterDate,
    filterInvestors
  };
};

export default useInvestors;
