import { useEffect, useState } from "react";
import { InvestorPortalApi } from "../Api/api.InvestorPortal";
import { useLocation } from "react-router-dom";

const useMedia = (props: any) => {
  const { language } = props;
  let location = useLocation();
  const [videosData, setVideosData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [newslettersData, setNewslettersData] = useState([]);
  const [loadingNews, setLoadingNews] = useState(false); 
  const [loadingMedia, setLoadingMedia] = useState(false); 
  const [recentActivityData, setRecentActivityData] = useState<any>([]);
  const [maxLoad, setMaxLoad] = useState(10); 

  useEffect(() => {
    if(location.pathname === '/Media'){
      getNews(language)
      getMedia(language)
      return
    }
    getRecentActivity(language);
  }, [language]);

  const getRecentActivity = async (lang: string) => {
    setLoadingNews(true);
    await InvestorPortalApi.getRecentActivity(lang)
      .then((response: any) => {
        if (response.success) {
          setRecentActivityData(response.data);
        }
        setLoadingNews(false)
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoadingNews(false));
  };

  const getNews = async (lang: string) => {
    setNewsData([]);
    setMaxLoad(10);
    setLoadingNews(true);
    await InvestorPortalApi.getNews(lang)
      .then((response: any) => {
        if (response.success) {
          setNewsData(response.data);
        }
        setLoadingNews(false)
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoadingNews(false));
  };

  const getMedia = async (lang: string) => {
    setLoadingMedia(true);
    await InvestorPortalApi.getMedia(lang)
      .then((response: any) => {
        if (response.success) {
          let newsletters = response.data.filter((item: any) => item.title === 'Newsletter');
          let videos = response.data.filter((item: any) => item.title === 'Video');
          if(newsletters.length > 0){
            setNewslettersData(newsletters[0].data ?? []);
          }
          if(videos.length > 0){
            setVideosData(videos[0].data ?? []);
          }
        }
        setLoadingMedia(false)
      })
      .catch((error: any) => {
        console.log(error);
      })
      .finally(() => setLoadingMedia(false));
  };

  const onLoadMore = () => {
    if(maxLoad === 10){
      setMaxLoad(20);
    }
    else{
      window.open('https://qualitasfunds.com/actualidad/','_blank');    
    }
  }

  return {
    videosData,
    newsData,
    newslettersData,
    loadingNews,
    recentActivityData,
    onLoadMore,
    maxLoad,
    loadingMedia
  };
};

export default useMedia;
