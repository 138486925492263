import axios from 'axios';

export const notifyError = async (baseUrl: string, url: string, userId: any, payload: any, token: any) => {

	let service = url.includes('qualitasfunds.com') ? url : `${baseUrl}${url}`;
	await axios.post(`${process.env.REACT_APP_CommUrl}/communications-api/AdvancedEmails/NotifyError`, {
		userId,
		url: service,
		payload: payload || 'No payload provided'
	},
	{
		headers: {'Authorization': `Bearer ${token}`}
	}
);
}
